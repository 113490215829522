import * as React from "../_snowpack/pkg/react.js";
import { useState, useEffect } from "../_snowpack/pkg/react.js";
import {
  getAllTests,
  runTests,
  formatTestResultsAsText
} from "../_snowpack/pkg/@benchristel/taste.js";
import "./TestResults.css";
import { integrationSuite } from "./integrationTestSuite.js";
export function TestResults(props) {
  const [message, setMessage] = useState("");
  useEffect(() => {
    runTests(props.suite.getAllTests()).then(formatTestResultsAsText).then(setMessage).catch((e) => setMessage(e.message));
  }, []);
  if (message === "") {
    return null;
  }
  return /fail/i.test(message) ? /* @__PURE__ */ React.createElement("div", {
    className: "TestResults"
  }, /* @__PURE__ */ React.createElement("code", null, /* @__PURE__ */ React.createElement("pre", null, message))) : /* @__PURE__ */ React.createElement("div", {
    className: "TestSuccess"
  });
}
