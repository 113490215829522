import * as React from "../_snowpack/pkg/react.js";
import "./App.css";
import { test, expect, is } from "../_snowpack/pkg/@benchristel/taste.js";
import { Bus } from "../_snowpack/pkg/@taliesinsoftworks/crdtbus.js";
import "./lib/useBus.js";
const dieShapes = [4, 6, 8, 10, 12];
const viewModel = {
  log: [
    {
      agent: "Gavin",
      results: [{ shape: 6, face: 4 }]
    },
    {
      agent: "Ben",
      results: [{ shape: 8, face: 5 }]
    }
  ],
  dicePools: [
    {
      agent: "Gavin",
      counts: { [4]: 0, [6]: 1, [8]: 2, [10]: 3, [12]: 4 }
    }
  ]
};
function Die(props) {
  return "d" + props.shape;
}
function Pool({ agent, counts }) {
  return /* @__PURE__ */ React.createElement("div", null, agent, /* @__PURE__ */ React.createElement("ul", null, dieShapes.map((shape, i) => /* @__PURE__ */ React.createElement("li", {
    key: i
  }, /* @__PURE__ */ React.createElement(Die, {
    shape
  }), " ", counts[shape]))));
}
function LogEntry({
  agent,
  results
}) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", {
    className: "agent"
  }, agent), results.map((r) => r.shape + ":" + r.face));
}
export function App() {
  return /* @__PURE__ */ React.createElement("div", {
    className: "App"
  }, /* @__PURE__ */ React.createElement("ul", {
    className: "Palette"
  }, dieShapes.map((shape, i) => /* @__PURE__ */ React.createElement("li", {
    key: i
  }, /* @__PURE__ */ React.createElement(Die, {
    shape
  })))), /* @__PURE__ */ React.createElement("div", {
    className: "Pools"
  }, viewModel.dicePools.map((pool, i) => /* @__PURE__ */ React.createElement(Pool, {
    ...pool,
    key: i
  }))), /* @__PURE__ */ React.createElement("ul", {
    className: "Log"
  }, viewModel.log.map((entry, i) => /* @__PURE__ */ React.createElement("li", {
    key: i
  }, /* @__PURE__ */ React.createElement(LogEntry, {
    ...entry
  })))));
}
