import * as React from "../_snowpack/pkg/react.js";
import { render } from "../_snowpack/pkg/react-dom.js";
import { App } from "./App.js";
import { TestResults } from "./TestResults.js";
import { test, getAllTests, expect, is } from "../_snowpack/pkg/@benchristel/taste.js";
import "./global.css";
import { integrationSuite } from "./integrationTestSuite.js";
const appElement = document.getElementById("app");
const testResultsElement = document.getElementById("test-results");
if (!appElement || !testResultsElement) {
  throw "couldn't find element to render into";
}
render(/* @__PURE__ */ React.createElement(App, null), appElement);
render(/* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(TestResults, {
  suite: { test, getAllTests }
}), /* @__PURE__ */ React.createElement(TestResults, {
  suite: integrationSuite
})), testResultsElement);
