import * as React from "../../_snowpack/pkg/react.js";
import { useEffect } from "../../_snowpack/pkg/react.js";
import { expect, equals, not, is } from "../../_snowpack/pkg/@benchristel/taste.js";
import { Bus } from "../../_snowpack/pkg/@taliesinsoftworks/crdtbus.js";
import { uiTest } from "./testUtils.js";
import { render } from "../../_snowpack/pkg/react-dom.js";
import { integrationSuite } from "../integrationTestSuite.js";
function useBus(params) {
  const busRef = React.useRef();
  const [nonce, setNonce] = React.useState(0);
  busRef.current ||= Bus({
    topic: params.topic,
    agentId: params.agentId,
    load: params.load,
    save: params.save,
    merge: params.merge,
    signalingServerHost: "drpeer2.onrender.com"
  });
  const bus = busRef.current;
  bus.subscriber = () => setNonce(nonce + 1);
  useEffect(() => bus.destroy);
  return bus;
}
function MaxTracker(props) {
  const bus = useBus({
    topic: props.topic,
    load: () => 0,
    save: () => {
    },
    merge: (a, b) => Math.max(a, b),
    agentId: props.agentId
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", null, "Present: ", [...bus.whosOnline.get()].filter(Boolean).sort().join(", ")), /* @__PURE__ */ React.createElement("button", {
    onClick: () => bus.apply(1)
  }, bus.state.get()));
}
integrationSuite.test("useBus", {
  "maintains state": uiTest(async (el) => {
    render(/* @__PURE__ */ React.createElement(MaxTracker, {
      agentId: "some-id",
      topic: randomTopic()
    }), el);
    await wait(0);
    const button = el.querySelector("button");
    expect(button, not(is), null);
    button?.click();
    await wait(0);
    expect(button?.innerText, equals, "1");
  }),
  "exchanges data": uiTest(async (el) => {
    const myTopic = randomTopic();
    render(/* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MaxTracker, {
      agentId: "some-id",
      topic: myTopic
    }), /* @__PURE__ */ React.createElement(MaxTracker, {
      agentId: "some-id",
      topic: myTopic
    })), el);
    await wait(0);
    const [button1, button2] = el.querySelectorAll("button");
    button1.click();
    await wait(5e3);
    expect(button2.innerText, equals, "1");
  }),
  "reveals participants": uiTest(async (el) => {
    const myTopic = randomTopic();
    render(/* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MaxTracker, {
      agentId: "agent-one",
      topic: myTopic
    }), /* @__PURE__ */ React.createElement(MaxTracker, {
      agentId: "agent-two",
      topic: myTopic
    })), el);
    await wait(5e3);
    const [whosOnline] = el.querySelectorAll("p");
    expect(whosOnline.innerText, equals, "Present: agent-one, agent-two");
  }),
  "does not share data between topics": uiTest(async (el) => {
    render(/* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MaxTracker, {
      agentId: "some-id",
      topic: randomTopic()
    }), /* @__PURE__ */ React.createElement(MaxTracker, {
      agentId: "some-id",
      topic: randomTopic()
    })), el);
    await wait(0);
    const [button1, button2] = el.querySelectorAll("button");
    button1.click();
    await wait(5e3);
    expect(button1.innerText, equals, "1");
    expect(button2.innerText, equals, "0");
  }),
  "does not log React errors": uiTest(async (el) => {
    const originalConsoleError = console.error;
    const errorLogs = [];
    console.error = (...args) => {
      errorLogs.push(args);
    };
    try {
      const myTopic = randomTopic();
      render(/* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(MaxTracker, {
        agentId: "agent-1",
        topic: myTopic
      }), /* @__PURE__ */ React.createElement(MaxTracker, {
        agentId: "agent-2",
        topic: myTopic
      })), el);
      await wait(0);
      render(/* @__PURE__ */ React.createElement(React.Fragment, null), el);
      await wait(5e3);
      expect(errorLogs, equals, []);
    } finally {
      console.error = originalConsoleError;
    }
  })
});
function wait(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
function randomTopic() {
  return `topic-${Math.random()}`.replace(".", "-");
}
